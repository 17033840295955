<script>
import HttpRequest from "@/http/HttpRequest";
import Utils from "@/helpers/Utils";
import Preference from "@/data/Preference";
import { bus } from "@/views/pages/dashboard/index";

let httpRequest = new HttpRequest();
let preference = new Preference();

export default {
  props: ["scope"],
  data() {
    return {
      isLoading: false,
      series: [
        {
          name: "Execution(hour)",
          type: "bar",
          data: [],
        },
        {
          name: "Thinking(hour)",
          type: "bar",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 342,
          type: "bar",
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            borderRadius: 5,
            columnWidth: "30%",
          },
          dataLabels: {
            enabled: false,
          },
        },
        zoom: {
          enabled: true,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
        },
        colors: ["#4B1FE5", "#1cbb8c"],
        labels: [],
      },
      delay: preference.getData(Preference.KEY_AUTO_REFRESH_TIME),
      runner: null,
    };
  },
  methods: {
    getActivityChart() {
      let _this = this;
      let fromDate = Utils.getDaysAgo(7);
      let toDate = Utils.getToday();

      _this.isLoading = true;
      httpRequest
        .getActivityChart(fromDate, toDate, this.scope)
        .then(function (response) {
          _this.isLoading = false;
          if (response.success) {
            let data = response.data;
            _this.series[0].data = [];
            _this.series[1].data = [];
            _this.sortByDate(data);
            for (let i = 0; i < data.length; i++) {
              _this.chartOptions.labels[i] = Utils.formatDateTime(data[i].date, "DD MMM");
              _this.series[0].data[i] = (
                (data[i].total_time - data[i].idle_time) /
                3600.0
              ).toFixed(1); // Thinking Time
              _this.series[1].data[i] = (data[i].idle_time / 3600.0).toFixed(1); // Executing Time
            }
            window.dispatchEvent(new Event("resize"));
          }
        });
    },
    sortByDate(array) {
      array.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      });
    },
  },
  async created() {
    // auto refresh by updated value
    bus.$on("auto_refresh_val", (data) => {
      this.delay = data;
      clearInterval(this.runner);
      if (this.delay > 0) {
        this.runner = setInterval(this.getActivityChart, this.delay * 60 * 1000);
      }
    });
    await this.getActivityChart();
    // auto refresh by previously set value
    clearInterval(this.runner);
    if (this.delay > 0) {
      setInterval(await this.getActivityChart, this.delay * 60 * 1000);
    }
  },
  watch: {
    scope: function () {
      this.getActivityChart();
    },
  },
};
</script>

<template>
  <div class="mb-4">
    <div class="card h-100-" style="height: 435px" v-if="!isLoading">
      <div class="card-body">
        <h4 class="card-title mb-4">Activity Chart</h4>
        <div>
          <apexchart
            class="apex-charts"
            height="342"
            dir="ltr"
            :series="series"
            :options="chartOptions"
          ></apexchart>
        </div>
      </div>
    </div>
    <div class="row" v-if="isLoading">
      <div class="col-12" style="min-height: 118px">
        <div class="card h-100">
          <div class="card-body">
            <div class="text-center mt-4 mb-4">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
