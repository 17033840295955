<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
}
</script>

<!--<template>-->
<!--  &lt;!&ndash; start page title &ndash;&gt;-->
<!--  <div class="row">-->
<!--    <div class="col-12">-->
<!--      <div class="page-title-box d-flex flex-column flex-md-row align-items-center justify-content-between">-->
<!--        <h4 class="mb-md-0">{{ title }}</h4>-->

<!--        <div class="page-title-right">-->
<!--          <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  &lt;!&ndash; end page title &ndash;&gt;-->
<!--</template>-->
