<script>
import HttpRequest from "@/http/HttpRequest";
// import Utils from "@/helpers/Utils";
import Preference from "@/data/Preference";
// import { bus } from "@/views/pages/dashboard/index";

let httpRequest = new HttpRequest();
let preference = new Preference();

export default {
  props: ["scope"],
  data() {
    return {
      isLoading: false,
      series: [
        {
          name: "Execution(hour)",
          type: "bar",
          data: [],
        },
        {
          name: "Thinking(hour)",
          type: "bar",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 5,
            columnWidth: "85%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [],
        },
        fill: {
          opacity: 1,
        },
        legend: {
          show: false,
        },
        colors: ["#00E396", "#008FFB"],
      },

      delay: preference.getData(Preference.KEY_AUTO_REFRESH_TIME),
      runner: null,
    };
  },
  methods: {
    getDashboardTopUsers(order) {
      let _this = this;

      _this.isLoading = true;
      httpRequest.getDashboardTopUsers(order).then(function (response) {
        _this.isLoading = false;
        if (response.success) {
          let data = response.data;
          _this.series[0].data = [];
          _this.series[1].data = [];
          _this.chartOptions.xaxis.categories = [];
          data.forEach(function (activity) {
            _this.series[1].data.push(
              (activity.total_idle_seconds / 3600.0).toFixed(1)
            );
            _this.series[0].data.push(
              (activity.net_active_seconds / 3600.0).toFixed(1)
            );

            let namesArray = activity.name.split(" "); // Splitting the name string into an array of names
            _this.chartOptions.xaxis.categories.push(namesArray);
          });

          //window.dispatchEvent(new Event("resize"));
        }
      });
    },
    sortByDate(array) {
      array.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      });
    },
    changeOption(option) {
      this.selectedOption = option; // Update selected option
      if (option == "Top") {
        this.getDashboardTopUsers("DESC");
      } else {
        this.getDashboardTopUsers("ASC");
      }
    },
  },

  async created() {

    await this.getDashboardTopUsers("DESC");
    // auto refresh by previously set value
    clearInterval(this.runner);
    if (this.delay > 0) {
      setInterval(await this.getDashboardTopUsers, this.delay * 60 * 1000);
    }
  },
};
</script>

<template>
  <div class="">
    <div class="card h-100-" style="height: 435px" v-if="!isLoading">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <div>
            <h4 class="card-title mb-4">Productivity Chart</h4>
          </div>

          <div>
            <b-dropdown right variant="black" toggle-class="header-item">
              <template v-slot:button-content>
                <span>{{ selectedOption }}</span>
                <i class="mdi mdi-chevron-down"></i>
              </template>

              <a @click="changeOption('Top')" class="dropdown-item">
                <i class="ri-arrow-up-line align-middle mr-1"></i>
                Top
              </a>
              <a @click="changeOption('Least')" class="dropdown-item text">
                <i class="ri-arrow-down-line align-middle mr-1 text"></i>
                Least
              </a>
            </b-dropdown>
          </div>
        </div>

        <div id="chart">
          <apexchart
            type="bar"
            height="340"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
      </div>
    </div>
    <div class="row" v-if="isLoading">
      <div class="col-12" style="min-height: 435px">
        <div class="card h-100">
          <div class="card-body">
            <div class="text-center" style="margin-top: 170px">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.dropdown-item {
  cursor: pointer; /* Add pointer cursor to dropdown items */
}
tspan {
  font-weight: bolder;
}
</style>


