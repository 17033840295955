<script>
export default {
  data() {
    return {
      appWin: "",
      appMacIntel: "",
      appMacSilicon: "",
      appUbuntu: "",
    };
  },
  methods: {
    initAppUrls() {
      this.appWin = process.env.VUE_APP_DESKTOP_APP_WIN;
      this.appMacIntel = process.env.VUE_APP_DESKTOP_APP_MAC_INTEL;
      this.appMacSilicon = process.env.VUE_APP_DESKTOP_APP_MAC_SILICON;
      this.appUbuntu = process.env.VUE_APP_DESKTOP_APP_UBUNTU;
    },
  },
  async created() {
    this.initAppUrls();
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="d-flex align-items-center mt-3">
        <div class="w-100">
          <div class="d-flex justify-content-center">
            <img src="@/assets/images/bg/empty.png" height="300px" width="auto" alt="logo" />
          </div>
          <h4 class="font-size-28 text-center">No data tracked yet!</h4>
          <p class="text-center">
            Please download our desktop app and track work activities
          </p>
          <div class="d-flex justify-content-center mt-3">
            <b-btn :href="appWin" class="download-btn ml-2" size="lg"><i class="ri-windows-line"
                style="vertical-align: bottom"></i>
              Windows</b-btn>
            <b-btn :href="appMacIntel" class="customs-bg-color ml-2" size="lg"><i class="ri-apple-line"
                style="vertical-align: bottom"></i> MacOS (Intel)</b-btn>
            <b-btn :href="appMacSilicon" class="customs-bg-color ml-2" size="lg"><i class="ri-apple-line"
                style="vertical-align: bottom"></i> MacOS (Silicon)</b-btn>
            <b-btn :href="appUbuntu" class="download-btn ml-2" size="lg">
              <i class="ri-ubuntu-line" style="vertical-align: bottom"></i> Ubuntu</b-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.download-btn {
  background-color: #ff7c47 !important;
  border: none;
}

.customs-bg-color {
  background-color: #ff7c47 !important;
}
</style>
