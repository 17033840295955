class Constants {

    static DASHBOARD_SCOPE_ME = 'me'
    static DASHBOARD_SCOPE_ALL = 'all'

    static AWS_FILE_BASE_URL = 'https://s3.us-west-2.amazonaws.com/tracker.w3engineers.com/'
    static CLOUDEFRONT_SIGNED_URL = 'https://d5sil68ee7gk0.cloudfront.net'
    // static CLOUDEFRONT_SIGNED_URL = 'https://d1ovj4biikyvko.cloudfront.net/'

    static TASK_STATUS_OPEN = 0
    static TASK_STATUS_IN_PROGRESS = 1
    static TASK_STATUS_DONE = 2

    static ROLE_PRODUCT_OWNER = 6
    static ROLE_OWNER = 3

}

module.exports = Constants
