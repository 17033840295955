<script>
import Vue from "vue";
import HttpRequest from "@/http/HttpRequest";
import Preference from "@/data/Preference";
let httpRequest = new HttpRequest();
let preference = new Preference();
export default {
  props: ["scope"],

  data() {
    return {
      isLoading: false,
      workSpaceUsersTableData: [],
      userCurrentPage: 1,
      workSpaceUsersList: [],
      userPerPage: 50,
      filterMembers: null,
      onlineMembers: 0,
      offlineMembers: 0,
      absentMembers: 0,
      idleMembers: 0,
      todayDateTime: new Date(),

      fields: [
        { key: "avatar", sortable: false, label: "", thStyle: { width: "100px" } },
        { key: "name", sortable: false, label: "Name" },
        { key: "email", sortable: false, label: "Email" },
      ],
      items: [],
    };
  },
  computed: {
    userRowOnline() {
      console.log(preference.getData(Preference.KEY_ROLE_ID));

      return this.onlineMembers;
    },
    userRowOffline() {
      return this.offlineMembers;
    },
    userRowIdle() {
      return this.idleMembers;
    },
    onlineFilteredTableData() {
      let filteredData = this.workSpaceUsersTableData.filter(
        (item) => item.online_status == 1
      );
      return filteredData;
    },
    offlineFilteredTableData() {
      let filteredData = this.workSpaceUsersTableData.filter(
        (item) => item.online_status == 0
      );
      return filteredData;
    },
    idleFilteredTableData() {
      let filteredData = this.workSpaceUsersTableData.filter(
        (item) => item.online_status == 2
      );
      return filteredData;
    },
    absentFilteredTableData() {
      let filteredData = this.workSpaceUsersTableData.filter(
        (item) => item.online_status == -1
      );
      return filteredData;
    },
  },
  watch: {
    filterMembers() {
      this.userCurrentPage = 1;
    },
  },
  methods: {
    getWorkSpaceMembersList() {
      let _this = this;
      _this.loadingOrganizations = true;
      _this.isLoading = true;

      return httpRequest.getWorkSpaceMembersList().then(function (response) {
        _this.isLoading = false;
        _this.loadingOrganizations = true;
        if (response.success) {
          _this.workSpaceUsersList = response.data;
          _this.workSpaceUsersTableData.length = 0;
          let tempObj = {};
          let onlineStatus = 0;
          let todayDate = _this.todayDateTime;
          _this.workSpaceUsersList.forEach(function (userData) {
            let updatedDate = new Date(userData.updated_at);
            let differenceInMinutes = (todayDate - updatedDate) / 60000;

            // if (userData.name == 'Syed Ekram Uddin') console.log(userData.role_id);

            if (differenceInMinutes < 60 * 24 * 7 && userData.role_id != 3) {
              if (userData.online_status > 2) {
                if (differenceInMinutes > 15) {
                  _this.offlineMembers++;

                  onlineStatus = 0;
                } else {
                  onlineStatus = 1;
                  _this.onlineMembers++;
                }
              } else if (userData.online_status == 0) {


                if (differenceInMinutes > 20) {
                  if (_this.isDifferentDate(todayDate, updatedDate)) {
                    _this.absentMembers++
                    onlineStatus = -1;

                  } else {
                    _this.offlineMembers++;
                    onlineStatus = 0;
                  }

                } else {
                  _this.idleMembers++;
                  onlineStatus = 2;
                }
              }

              tempObj = {
                avatar: userData.image,
                name: userData.name,
                email: userData.email,
                online_status: onlineStatus,
                delay_time:
                  Math.ceil(differenceInMinutes) < 60
                    ? "Active " + Math.ceil(differenceInMinutes) + " minutes ago"
                    : "",
              };
              _this.workSpaceUsersTableData.push(tempObj);
            }
          });
        }
        return;
      });
    },

    isDifferentDate(todayDate, updatedDate) {
      // Convert dates to Date objects if they aren't already
      todayDate = new Date(todayDate);
      updatedDate = new Date(updatedDate);

      // Extract year, month, and day from both dates

      let todayDay = todayDate.getDate();

      let updatedDay = updatedDate.getDate();

      // Compare the year, month, and day
      return todayDay !== updatedDay;
    },

    refresh() {
      (this.workSpaceUsersTableData = []),
        (this.userCurrentPage = 1),
        (this.workSpaceUsersList = []),
        (this.userPerPage = 50),
        (this.filterMembers = null),
        (this.onlineMembers = 0),
        (this.absentMembers = 0),
        (this.offlineMembers = 0),
        (this.idleMembers = 0),
        (this.todayDateTime = new Date());
      this.getWorkSpaceMembersList();
    },

    handleTabChange() {
      this.userCurrentPage = 1;
    },

    addColumn() {
      this.organizationFields.push({ key: "action", sortable: false, label: "Action" });
      this.orgMemberFields.push({ key: "action", sortable: false, label: "Action" });
    },
    createOrganization() {
      let _this = this;
      _this.loadingOrganizations = true;
      let swal = this.$swal;
      httpRequest
        .createOrganization(_this.org_name, "0", _this.user_id, _this.org_address)
        .then(function (response) {
          _this.loadingOrganizations = false;
          if (response.success) {
            _this.$bvModal.hide("modal-add-org");
            _this.getOrganizationList();
          } else {
            swal(response.message);
          }
        });
    },
    editOrganization() {
      let _this = this;
      _this.loadingOrganizations = true;
      let swal = this.$swal;
      httpRequest
        .editOrganization(
          _this.editedItem.name,
          "0",
          _this.user_id,
          _this.editedItem.address,
          _this.editedItem.organization_id
        )
        .then(function (response) {
          _this.loadingOrganizations = false;
          if (response.success) {
            _this.$bvModal.hide("modal-edit-org");
            _this.getOrganizationList();
          } else {
            swal(response.message);
          }
        });
    },
    addOrgMember() {
      let _this = this;
      _this.loadingMembers = true;
      let swal = this.$swal;
      let userRoleID = this.roleIDMap.get(_this.memberUserRole);
      return httpRequest
        .addMemberToOrganization(
          _this.selectedOrgID,
          _this.memberUserEmail,
          _this.memberUserName,
          userRoleID
        )
        .then(function (response) {
          _this.loadingMembers = false;
          if (response.success) {
            _this.getOrgMembersList(_this.selectedOrgID);
            _this.$bvModal.hide("modal-1");
            swal("Member adding mail has been sent.");
            _this.memberUserName = "";
            _this.memberUserEmail = "";
          } else {
            swal(response.message);
          }
          return;
        });
    },
    getRoleList(role_type) {
      let _this = this;
      httpRequest.getRole(role_type).then(function (response) {
        if (response.success) {
          _this.currentRoleList = response.data;
          // For avoiding data collision
          Vue.set(_this.roleObjectList, 5, "Member");
          Vue.set(_this.roleObjectList, 4, "Owner");
        }
      });
    },
    getOrgMembersList(org_id) {
      let _this = this;
      _this.loadingMembers = true;
      httpRequest.getOrganizationMembers(org_id).then(function (response) {
        _this.loadingMembers = false;
        _this.selectedOrgID = org_id;
        if (response.success) {
          _this.orgMembersList = response.data;
          _this.orgMemberTableData.length = 0;

          let tempObj = {};
          _this.orgMembersList.forEach(function (orgMemberData, index) {
            if (
              orgMemberData.user.name == _this.myName &&
              orgMemberData.user.email == _this.myEmail
            ) {
              _this.myOrgRole = orgMemberData.role_id;
              if (_this.role == _this.role_admin || _this.myOrgRole == 4) {
                _this.addColumn();
              }
            }
            tempObj = {
              serial_number: index + 1,
              name: orgMemberData.user.name,
              email: orgMemberData.user.email,
              user_id: orgMemberData.user.user_id,
              user_role_id: orgMemberData.user.role_id,
              role_id: _this.displayValueMap.get(orgMemberData.role_id),
            };
            _this.orgMemberTableData.push(tempObj);
          });
        } else {
          _this.orgMembersList = [];
        }
      });
    },
    removeOrganizationMember(userData) {
      let _this = this;
      _this
        .$swal({
          text: "Are you sure you want to remove this member?",
          showCancelButton: true,
          confirmButtonText: "YES",
          cancelButtonText: "CANCEL",
        })
        .then((result) => {
          if (result.value) {
            httpRequest
              .deleteMemberFromOrganization(this.selectedOrganizationID, userData.email)
              .then(function (response) {
                if (response.success) {
                  _this.getOrgMembersList(_this.selectedOrganizationID);
                } else {
                  _this.$swal({
                    text: "You can't remove this member!",
                  });
                }
              });
          }
        });
    },
    editOrganizationMember() {
      let _this = this;
      // Display a modal with current state,
      _this.loadingMembers = true;
      let swal = this.$swal;
      let userRoleID = this.roleIDMap.get(_this.roleEditItem.role_id);
      return httpRequest
        .updateOrganizationMember(
          _this.selectedOrgID,
          _this.roleEditItem.email,
          _this.roleEditItem.name,
          userRoleID
        )
        .then(function (response) {
          _this.loadingMembers = false;
          if (response.success) {
            _this.getOrgMembersList(_this.selectedOrgID);
            _this.$bvModal.hide("modal-edit-role");
            swal(response.message);
          } else {
            swal(response.message);
          }
          return;
        });
    },
    onFilteredOrg(filteredItems) {
      this.totalRowsOrg = filteredItems.length;
      this.currentPage = 1;
    },
    onFilteredOrgMembers(filteredItems) {
      this.totalRowsMembers = filteredItems.length;
      this.currentPage = 1;
    },
    myRowClickHandler(record) {
      this.selected = record;
      this.selectedOrgName = record.name;
      this.selectedOrganizationID = record.organization_id;
      this.getOrgMembersList(record.organization_id);
    },
    myEditedItem(row) {
      this.editedItem = row;
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item == this.selected) {
        return "table-active";
      }
    },
    myRoleEditItem(item) {
      this.roleEditItem = item;
    },
    deleteDepartment(projectData) {
      let _this = this;
      _this
        .$swal({
          text: "Are you sure you want to delete this department?",
          showCancelButton: true,
          confirmButtonText: "YES",
          cancelButtonText: "CANCEL",
        })
        .then((result) => {
          if (result.value) {
            httpRequest
              .deleteDepartment(projectData.organization_id)
              .then(function (response) {
                if (response.success) {
                  _this.getOrganizationList();
                  _this.$swal({
                    text: "Delete Successful",
                  });
                } else {
                  _this.$swal({
                    text: "You can't delete this department!",
                  });
                }
              });
          }
        });
    },
  },
  async created() {
    await this.getWorkSpaceMembersList();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" />
    <div class="row" v-if="!isLoading">
      <div class="col-xl-12">
        <div class="card" style="height: 425px">
          <div class="card-body">
            <div id="members-table_filter" class="d-flex justify-content-between align-items-center dataTables_filter">
              <div class="d-flex">
                <h4 class="card-title mb-4" style="margin-top: 9px">Online Status</h4>
                <div class="align-items-center d-inline cursor-pointer-custom" v-b-tooltip.hover.top @click="refresh"
                  title="Refresh">
                  <i style="color: #ff7c47" class="ri-refresh-line font-size-24 px-2"></i>
                </div>
              </div>
              <div>
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input v-model="filterMembers" type="search"
                    class="form-control form-control-sm ml-2"></b-form-input>
                </label>
              </div>
            </div>
            <div>
              <b-tabs active-class="active-class" @input="handleTabChange">
                <!-- online users table -->
                <b-tab active>
                  <template #title>
                    <span class="green-dot"></span>
                    <strong>Online ({{ onlineMembers }})</strong>
                  </template>
                  <b-table hover sticky-header="250px" :items="onlineFilteredTableData" :fields="fields" show-empty
                    :current-page="userCurrentPage" class="mb-0" thead-tr-class="d-none" :bordered="true" :dark="false"
                    responsive="" :per-page="userPerPage" :filter="filterMembers">
                    <template v-for="field in fields" v-slot:[`head(${field.key})`]="{ label }">
                      <span class="" :style="{ color: '#ff7c47' }" v-bind:key="field.key">{{ label }}</span>
                    </template>

                    <template v-slot:cell(avatar)="data">
                      <b-avatar :src="data.item.avatar"></b-avatar>
                    </template>
                  </b-table>
                  <div class="col" v-if="onlineMembers">
                    <div class="dataTables_paginate paging_simple_numbers float-md-right">
                      <!-- online users pagination -->
                      <b-pagination v-model="userCurrentPage" :limit="3" :total-rows="userRowOnline"
                        :per-page="userPerPage" class="pagination-rounded mb-0 mt-1"></b-pagination>
                    </div>
                  </div>
                </b-tab>
                <!-- idle users table  -->
                <b-tab>
                  <template #title>
                    <span class="black-dot"></span>
                    <strong>Idle ({{ idleMembers }})</strong>
                  </template>
                  <b-table hover thead-tr-class="d-none" sticky-header="250px" :items="idleFilteredTableData"
                    :fields="fields" show-empty :current-page="userCurrentPage" class="mb-0" :bordered="true"
                    :dark="false" responsive="" :per-page="userPerPage" :filter="filterMembers">
                    <template v-for="field in fields" v-slot:[`head(${field.key})`]="{ label }">
                      <span class="" :style="{ color: '#ff7c47' }" v-bind:key="field.key">{{ label }}</span>
                    </template>

                    <template v-slot:cell(avatar)="data">
                      <b-avatar v-b-tooltip.hover.html="tipMethod" :title="data.item.delay_time"
                        :src="data.item.avatar"></b-avatar>
                    </template>
                    <template v-slot:cell(email)="data">
                      <a v-b-tooltip.hover.html="tipMethod" target="_blank" :href="'mailto:' + data.item.email"
                        class="cursor-pointer-custom text-dark">{{ data.item.email }}</a>
                    </template>
                  </b-table>
                  <div class="col" v-if="idleMembers">
                    <div class="dataTables_paginate paging_simple_numbers float-md-right">
                      <!-- idle users pagination -->
                      <b-pagination v-model="userCurrentPage" :limit="3" :total-rows="userRowIdle"
                        :per-page="userPerPage" class="pagination-rounded mb-0 mt-1"></b-pagination>
                    </div>
                  </div>
                </b-tab>
                <!-- offline users table  -->
                <b-tab>
                  <template #title>
                    <span class="red-dot"></span>
                    <strong>Offline ({{ offlineMembers }})</strong>
                  </template>
                  <b-table hover sticky-header="250px" :items="offlineFilteredTableData" :fields="fields" show-empty
                    :current-page="userCurrentPage" class="mb-0" :bordered="true" :dark="false" responsive=""
                    :per-page="userPerPage" :filter="filterMembers" thead-tr-class="d-none">
                    <template v-for="field in fields" v-slot:[`head(${field.key})`]="{ label }">
                      <span class="" :style="{ color: '#ff7c47' }" v-bind:key="field.key">{{ label }}</span>
                    </template>

                    <template v-slot:cell(avatar)="data">
                      <b-avatar v-b-tooltip.hover.html="tipMethod" :title="data.item.delay_time"
                        :src="data.item.avatar"></b-avatar>
                    </template>

                    <template v-slot:cell(email)="data">
                      <a v-b-tooltip.hover.html="tipMethod" target="_blank" :href="'mailto:' + data.item.email"
                        class="cursor-pointer-custom text-dark">{{ data.item.email }}</a>
                    </template>
                  </b-table>
                  <div class="col" v-if="offlineMembers">
                    <div class="dataTables_paginate paging_simple_numbers float-md-right">
                      <!-- offline users pagination -->
                      <b-pagination v-model="userCurrentPage" :limit="3" :total-rows="userRowOffline"
                        :per-page="userPerPage" class="pagination-rounded mb-0 mt-1"></b-pagination>
                    </div>
                  </div>
                </b-tab>

                <!-- absent users table -->
                <b-tab>
                  <template #title>
                    <span class="white-dot"></span>
                    <strong>Absent ({{ absentMembers }})</strong>
                  </template>
                  <b-table hover sticky-header="250px" :items="absentFilteredTableData" :fields="fields" show-empty
                    :current-page="userCurrentPage" class="mb-0" :bordered="true" :dark="false" responsive=""
                    :per-page="userPerPage" :filter="filterMembers" thead-tr-class="d-none">
                    <template v-for="field in fields" v-slot:[`head(${field.key})`]="{ label }">
                      <span class="" :style="{ color: '#ff7c47' }" v-bind:key="field.key">{{ label }}</span>
                    </template>

                    <template v-slot:cell(avatar)="data">
                      <b-avatar v-b-tooltip.hover.html="tipMethod" :title="data.item.delay_time"
                        :src="data.item.avatar"></b-avatar>
                    </template>

                    <template v-slot:cell(email)="data">
                      <a v-b-tooltip.hover.html="tipMethod" target="_blank" :href="'mailto:' + data.item.email"
                        class="cursor-pointer-custom text-dark">{{ data.item.email }}</a>
                    </template>
                  </b-table>
                  <div class="col" v-if="absentMembers">
                    <div class="dataTables_paginate paging_simple_numbers float-md-right">
                      <!-- absent users pagination -->
                      <b-pagination v-model="userCurrentPage" :limit="3" :total-rows="userRowOffline"
                        :per-page="userPerPage" class="pagination-rounded mb-0 mt-1"></b-pagination>
                    </div>
                  </div>
                </b-tab>

              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="isLoading">
      <div class="col-12 mb-4">
        <div class="card h-100">
          <div class="card-body">
            <div class="text-center mt-3 mb-3">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
.customs-bg-color {
  background-color: #ff7c47 !important;
  border: none;
}

.green-dot {
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50%;
  position: absolute;
  margin-top: 5px;
}

.red-dot {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  margin-top: 5px;
}

.white-dot {
  width: 10px;
  height: 10px;
  background-color: rgb(255 165 0);
  border: black;
  border-radius: 50%;
  position: absolute;
  margin-top: 5px;
}


.black-dot {
  width: 10px;
  height: 10px;
  background-color: #74788d;
  border-radius: 50%;
  position: absolute;
  margin-top: 5px;
}

strong {
  margin-left: 15px;
}

.cursor-pointer-custom {
  cursor: pointer;
}
</style>
