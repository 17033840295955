<script>
import HttpRequest from "@/http/HttpRequest";
import Utils from "@/helpers/Utils";
import Preference from "@/data/Preference";
import { bus } from "@/views/pages/dashboard/index";

let httpRequest = new HttpRequest();
let preference = new Preference();

export default {
  props: ["scope"],
  data() {
    return {
      role: preference.getData(Preference.KEY_ROLE_ID),

      isLoading: false,
      summaryData: [],
      delay: preference.getData(Preference.KEY_AUTO_REFRESH_TIME),
      runner: null,
    };
  },
  methods: {
    getSummary() {
      this.summaryData =
        this.role == 3
          ? [
            {
              title: "Total Hours (7days)",
              icon: "ri-time-line",
              value: "",
            },
            {
              title: "Active Hours (7days)",
              icon: "ri-timer-flash-line",
              value: "",
            },
            {
              title: "Productivity (7days)",
              icon: "ri-line-chart-fill",
              value: "",
            },
            {
              title: "Total Active Users",
              icon: "ri-team-line",
              value: "",
            },
            {
              title: "Total Active Dept",
              icon: "ri-user-3-line",
              value: "",
            },

            {
              title: "Total Active Projects",
              icon: "ri-pages-line",
              value: "",
            },
          ]
          : [
            {
              title: "Total Hours (7days)",
              icon: "ri-time-line",
              value: "",
            },
            {
              title: "Active Hours (7days)",
              icon: "ri-timer-flash-line",
              value: "",
            },
            {
              title: "Productivity (7days)",
              icon: "ri-line-chart-fill",
              value: "",
            },
            {
              title: "Total Active Projects",
              icon: "ri-pages-line",
              value: "",
            },
          ];

      let _this = this;
      let fromDate = Utils.getDaysAgo(7);
      let toDate = Utils.getToday();
      _this.isLoading = true;
      _this.$emit("isLoading", true);
      httpRequest.getSummary(fromDate, toDate, this.scope).then(function (response) {
        _this.isLoading = false;
        if (response.success) {
          let percent = Math.round(
            ((response.data.total_time - response.data.idle_time) * 100) /
            response.data.total_time
          );
          _this.summaryData[0].value = Utils.formatTime(response.data.total_time);
          _this.summaryData[1].value = Utils.formatTime(
            response.data.total_time - response.data.idle_time
          );
          _this.summaryData[2].value = percent + "%";
          if (_this.role == 3) {
            (_this.summaryData[3].value = response.data.total_users),
              (_this.summaryData[4].value = response.data.total_teams),
              (_this.summaryData[5].value = response.data.total_projects);
          } else {
            _this.summaryData[3].value = response.data.total_projects;
          }

          _this.$emit("isEmpty", false);
        } else {
          _this.$emit("isEmpty", true);
        }
        _this.$emit("isLoading", false);
      });
    },
  },
  async created() {
    // auto refresh by update value
    bus.$on("auto_refresh_val", (data) => {
      this.delay = data;
      clearInterval(this.runner);
      if (this.delay > 0) {
        this.runner = setInterval(this.getSummary, this.delay * 60 * 1000);
      }
    });

    await this.getSummary();
    // auto refresh by previously set value
    clearInterval(this.runner);
    if (this.delay > 0) {
      setInterval(await this.getSummary, this.delay * 60 * 1000);
    }
  },
  watch: {
    scope: function () {
      this.getSummary();
    },
  },
};
</script>

<template>
  <div>
    <div class="row" v-if="!isLoading">
      <div class="col-sm-4 col-lg-2 col-md-4 mb-4" v-for="(data, index) in summaryData" :key="index">
        <div class="card h-100">
          <div class="card-body">
            <div class="media">
              <div class="media-body overflow-hidden">
                <p class="font-size-12 mb-2">{{ data.title }}</p>
                <h4 class="mb-0 font-size-15">{{ data.value }}</h4>
              </div>
              <div class="text-primary-" style="color: #ff7c47">
                <i :class="`${data.icon} font-size-22`"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="isLoading">
      <div class="col-12 mb-4">
        <div class="card h-100">
          <div class="card-body">
            <div class="text-center mt-3 mb-3">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.boxShadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
</style>
