<script>
import HttpRequest from "@/http/HttpRequest";
import Utils from "@/helpers/Utils";

let httpRequest = new HttpRequest();

export default {
  props: ["scope"],
  data() {
    return {
      AppName: "",
      isLoading: false,
      isLoadingModal: false,
      appData: [],
      appUsersData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 6,
      fields: [
        { key: "app_name", sortable: false, label: "App" },
        { key: "work_hour", sortable: true, label: "Spent" },
        { key: "total_users", sortable: true, label: "User" },
      ],
      appUsersDatafields: [
        { key: "name", sortable: false, label: "Name" },
        { key: "email", sortable: false, label: "email" },
        { key: "occurrence", sortable: false, label: "Count" },
        { key: "active_sec", sortable: false, label: "Spent" },
      ],
    };
  },
  methods: {
    appUsersListModal(AppName) {
      this.AppName = AppName + " | Users";
      this.appUsersData = [];
      this.getAppsUser(AppName);
    },
    getAppsUser(AppName) {
      let _this = this;
      let fromDate = Utils.getDaysAgo(7);
      console.log(fromDate);
      let toDate = Utils.getToday();
      let timeZone = Utils.getMyTimezone();
      _this.isLoadingModal = true;
      httpRequest
        .getDashboardAppsUser(fromDate, toDate, timeZone, AppName, this.scope)
        .then(function (response) {
          _this.isLoadingModal = false;
          if (response.success) {
            for (let i = 0; i < response.data.length; i++) {
              let data = response.data[i];
              let item = {
                name: data.name,
                email: data.email,
                occurrence: data.occurrence,
                active_sec: Utils.formatTime(data.active_sec),
              };
              _this.appUsersData.push(item);
            }
          }
        });
    },
    getApps() {
      let _this = this;
      let fromDate = Utils.getDaysAgo(7);
      let toDate = Utils.getToday();
      let timeZone = Utils.getMyTimezone();

      _this.isLoading = true;
      httpRequest
        .getDashboardApps(fromDate, toDate, timeZone, this.scope)
        .then(function (response) {
          _this.isLoading = false;
          if (response.success) {
            _this.appData = [];
            _this.totalRows = response.data.length;
            for (let i = 0; i < _this.totalRows; i++) {
              let data = response.data[i];
              let totalHours = Utils.formatTime(data.active_time);
              let item = {
                app_name: data.app_name,
                work_hour: totalHours,
                total_users: data.count_project_member,
              };
              _this.appData.push(item);
            }
          }
        });
    },
  },
  async created() {
    await this.getApps();
  },
  watch: {
    scope: function () {
      this.getApps();
    },
  },
};
</script>

<template>
  <div class="card h-100-" style="height: 435px">
    <div class="card-body">
      <h4 class="card-title mb-3">Recent Apps</h4>

      <b-table
        class="mt-4 mb-0"
        :items="appData"
        :fields="fields"
        :striped="false"
        responsive=""
        :per-page="perPage"
        :current-page="currentPage"
        :busy="isLoading"
      >
        <template v-for="field in fields" v-slot:[`head(${field.key})`]="{ label }">
          <span class="" :style="{ color: '#ff7c47' }" v-bind:key="field.key">{{
            label
          }}</span>
        </template>
        <template v-slot:cell(total_users)="data">
          <span
            v-b-modal.modal-1
            variant="primary-"
            class="text-decoration-underline"
            v-on:click="appUsersListModal(data.item.app_name)"
          >
            {{ data.value }}
          </span>
        </template>
        <template #table-busy>
          <div class="text-center">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
      </b-table>
    </div>

    <div class="card-footer bg-white">
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-md-right">
            <b-pagination
              v-model="currentPage"
              :limit="3"
              :total-rows="totalRows"
              :per-page="perPage"
              class="pagination-rounded mb-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-1"
      centered
      :title="this.AppName"
      hide-footer
      size="lg"
      @hidden="clearForm"
    >
      <div>
        <b-table
          :items="appUsersData"
          :fields="appUsersDatafields"
          sticky-header
          responsive="sm"
          :busy="isLoadingModal"
        >
          <template #table-busy>
            <div class="text-center">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
        </b-table>
        <div style="display: flex; justify-content: end"></div>
      </div>
    </b-modal>
  </div>
</template>

<style>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #ff7c47;
  border-color: #4b1fe5;
}
</style>

<style>
.text-decoration-underline {
  text-decoration: underline;
}
</style>
