<script>
// Import the necessary module for HTTP requests
import HttpRequest from "@/http/HttpRequest";

// Create a new instance of HttpRequest
let httpRequest = new HttpRequest();

export default {
  // Define props for the component
  props: ["scope"],

  // Define the data properties for the component
  data() {
    return {
      // Flag to determine if the app is the latest version
      isLatestVersionApp: false,
    };
  },

  // Define methods for the component
  methods: {
    // Method to check if the app is the latest version
    isLatestVersionApps() {
      // Store the reference to the component instance
      let _this = this;

      // Variables to store version information
      let updatedVersion = "";
      let currentVersion = "";

      // Get the new version number through an HTTP request
      httpRequest.getNewVersionNumber().then(function (NewVersionNumber) {
        if (NewVersionNumber.success) {
          updatedVersion = NewVersionNumber.data.version;

          // Get the current version number through another HTTP request
          httpRequest.getCurrentVersionNumber().then(function (CurrentVersionNumber) {
            if (CurrentVersionNumber.success) {
              currentVersion = CurrentVersionNumber.data.app_version.toString();

              // Compare the versions to determine if the app is outdated
              if (
                updatedVersion[currentVersion.match(/\(([^)]+)\)/)[1]] ==
                currentVersion.split(" ")[0]
              ) {
                _this.isLatestVersionApp = false;
              } else {
                _this.isLatestVersionApp = true;
              }
            }
          });
        }
      });
    },
  },
  // Lifecycle hook - called when the component is created
  async created() {
    // Call the method to check for the latest version when the component is created
    this.isLatestVersionApps();
  },
};
</script>

<template>
  <!-- Template for the component -->
  <Layout>
    <div class="">
      <!-- Display an alert if the app is not the latest version -->
      <b-alert v-if="isLatestVersionApp" class="text-center" show variant="danger">
        You are using an outdated version of the WorkAny application.
        <a href="/download">Upgrade</a> now to enjoy enhanced features and a smoother
        experience.
      </b-alert>
    </div>
  </Layout>
</template>

<style scoped></style>
